import clsx from "clsx";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { NetworkIcon, SubstationIcon } from "@eisox/icons";
import { createContext, useBem } from "@eisox/tools";

import { routeToNetwork } from "~/routes/utils";
import type { HeatingNetwork as HeatingNetworkType } from "~/socketio/types";

import { getSubstations } from "../../helpers";
import { useBoilerRoomContextWithCurrentBoilerRoom } from "../../providers";
import type { Substation } from "../../types/boiler-room";
import { Card } from "../Card";
import { ECS } from "../ECS";
import { HeatingCurveDialog } from "../HeatingCurveDialog";
import { HistoryExitPopup } from "../HistoryExitPopup";
import { MultiWayValve } from "../MultiWayValve";
import { NetworkDialog } from "../NetworkDialog";
import { Pumps } from "../Pumps";
import { SetpointTemperature } from "../SetpointTemperature";
import { SubstationsPopup } from "../SubstationsPopup";
import { Temperatures } from "../Temperatures";

import styles from "./HeatingNetwork.module.scss";

const NAME = "HeatingNetwork";

interface HeatingNetworkContextValue {
  boilerRoomId: string;
  boilerRoomName: string;
  heatingNetwork: HeatingNetworkType;
  substations: Substation[];
  orientation: "up" | "down";
}

const [HeatingNetworkContextProvider, useHeatingNetworkContext] = createContext<HeatingNetworkContextValue>(NAME);

export interface HeatingNetworkProps extends HeatingNetworkType {
  orientation?: "up" | "down";
  className?: string;
}

export const HeatingNetwork: React.FC<HeatingNetworkProps> = ({ orientation = "up", className, ...hn }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const heatingNetworkStyle = bem("heating-network");

  const {
    id,
    name,
    type,
    setpointTemperature,
    startingTemperature,
    returnTemperature,
    startingTemperaturesECS,
    returnTemperaturesECS,
    tankSanitaryHotWaterTemperature,
    pumps,
    threeWaysValve,
    recyclePumpsECS,
  } = hn;

  const { houseId, currentBoilerRoom, boilerRooms, history } = useBoilerRoomContextWithCurrentBoilerRoom(NAME);

  const boilerRoomId = currentBoilerRoom.id;
  const boilerRoomName = currentBoilerRoom.name ?? "-";
  const substations = hn.objectName ? getSubstations(hn.objectName, boilerRooms ?? []) : [];

  const isECS = type === "sanitaryHotWater";

  return (
    <HeatingNetworkContextProvider
      boilerRoomId={boilerRoomId}
      boilerRoomName={boilerRoomName}
      heatingNetwork={hn}
      substations={substations}
      orientation={orientation}
    >
      <div className={clsx(heatingNetworkStyle({ orientation, ECS: isECS }), className)}>
        {isECS ? (
          <ECS
            name={name}
            startingTemperaturesECS={startingTemperaturesECS}
            returnTemperaturesECS={returnTemperaturesECS}
            recyclePumpsECS={recyclePumpsECS}
            tankSanitaryHotWaterTemperature={tankSanitaryHotWaterTemperature}
            threeWayValve={threeWaysValve}
          />
        ) : (
          <>
            {history ? (
              <HistoryExitPopup>
                <Card title={name?.toUpperCase() ?? "-"} icon={<NetworkIcon />} />
              </HistoryExitPopup>
            ) : !!threeWaysValve ||
              setpointTemperature !== undefined ||
              type === "radiator" ||
              type === "sanitaryHotWater" ? (
              <Link className={heatingNetworkStyle("link")} to={routeToNetwork(houseId, boilerRoomId, id)}>
                <Card title={name?.toUpperCase() ?? "-"} icon={<NetworkIcon />} />
              </Link>
            ) : (
              <HeatingCurveDialog network={hn}>
                <Card title={name?.toUpperCase() ?? "-"} icon={<NetworkIcon />} />
              </HeatingCurveDialog>
            )}
            {substations.length > 0 && (
              <SubstationsPopup
                houseId={houseId}
                boilerroomName={boilerRoomName}
                networkName={name}
                startingTemperature={startingTemperature}
                returnTemperature={returnTemperature}
                substations={substations}
              >
                <Card
                  title={formatMessage({ id: "boilerroom.substations" }, { sub: substations.length })}
                  icon={<SubstationIcon />}
                  orientation={orientation}
                />
              </SubstationsPopup>
            )}
            {setpointTemperature !== undefined &&
              (history ? (
                <HistoryExitPopup>
                  <SetpointTemperature setpointTemperature={setpointTemperature} command={threeWaysValve?.command} />
                </HistoryExitPopup>
              ) : (
                <Link className={heatingNetworkStyle("link")} to={routeToNetwork(houseId, boilerRoomId, id)}>
                  <SetpointTemperature setpointTemperature={setpointTemperature} command={threeWaysValve?.command} />
                </Link>
              ))}
          </>
        )}
        {(startingTemperature !== undefined || returnTemperature !== undefined) && (
          <Temperatures startingTemperature={startingTemperature} returnTemperature={returnTemperature} />
        )}
        {pumps && (
          <NetworkDialog
            dialogTitle={name ?? "-"}
            networkId={id}
            pumps={pumps}
            recyclePumpsECS={recyclePumpsECS}
            threeWaysValve={threeWaysValve}
          >
            <Pumps className={heatingNetworkStyle("pumps", { orientation })} {...pumps} />
          </NetworkDialog>
        )}
        {threeWaysValve && (
          <NetworkDialog
            dialogTitle={name ?? "-"}
            networkId={id}
            pumps={pumps}
            recyclePumpsECS={recyclePumpsECS}
            threeWaysValve={threeWaysValve}
          >
            <MultiWayValve
              className={heatingNetworkStyle("three-way-valve", { orientation })}
              type={3}
              {...threeWaysValve}
            />
          </NetworkDialog>
        )}
      </div>
    </HeatingNetworkContextProvider>
  );
};

export { HeatingNetworkContextProvider, useHeatingNetworkContext };
