import { useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, useParams, useRouteLoaderData } from "react-router-dom";

import type { PlansMessageInner } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";
import { Alert, Breadcrumb, ButtonV2 as Button, Loader, Typography } from "@eisox/design-system";
import { SunIcon } from "@eisox/icons";

import { LiveHistoryToggle, Page } from "~/UI/components";
import type { loader } from "~/UI/screens/House";
import type { ErrorMessageFormated } from "~/features/BoilerRooms";
import {
  ActionsDropdownMenu,
  Boilerroom,
  DisplayDropdownMenu,
  ErrorCollapsible,
  ErrorDialog,
  HistorySlider,
  Parameters,
  PositioningDialog,
  RightColumn,
  getBoilerRoomsFromModules,
  getErrors,
  useBoilerRoomContext,
  useModeContext,
} from "~/features/BoilerRooms";
import { RoomsDialog, getHeatingNetworkToConfigure } from "~/features/HeatingNetworkConfiguration";
import { idLoaderHouse, routeToFrostFreeSettings, routeToPlan, routeToSeasonSettings } from "~/routes/utils";
import type { Error } from "~/socketio/types";
import { useUserPreferenceStore } from "~/stores/userPreference";

import styles from "./BoilerRoom.module.scss";

const NAME = "BoilerRoomPage";

const BoilerRoomPage: React.FC = () => {
  const { t } = useTranslation();

  const { houseId, boilerroomId } = useParams() as { houseId: string; boilerroomId: string };
  const { plans, boilerroomPos, gateways, modules } = useRouteLoaderData(idLoaderHouse) as LoaderData<typeof loader>;

  const layout = useUserPreferenceStore.use.boilerroom().layout;

  const { mode, onModeChange } = useModeContext(NAME);
  const { currentBoilerRoom, boilerRooms, errors: realTimeErrors, date, history } = useBoilerRoomContext(NAME);

  const [positioningDialogOpen, setPositioningDialogOpen] = useState(
    boilerroomPos.filter(bpos => bpos.boilerRooms?.some(b => b.plan === undefined)).length > 0,
  );
  const prevErrorMessages = useRef<Error[]>();
  const [errorMessages, setErrorMessages] = useState<ErrorMessageFormated[]>([]);

  const newError = JSON.stringify(realTimeErrors) !== JSON.stringify(prevErrorMessages.current);

  if (newError) {
    prevErrorMessages.current = realTimeErrors;
    setErrorMessages(prev => [
      {
        date: dayjs().format("L - LTS"),
        messages: realTimeErrors,
      },
      ...prev,
    ]);
  }

  const boilerRoomModuleId = boilerroomPos.find(b => b.boilerRooms?.some(b => b.id === boilerroomId))?.moduleId;

  const heatingNetworksToConfigure = useMemo(() => getHeatingNetworkToConfigure(boilerRooms), [boilerRooms]);

  const boilerRoom = getBoilerRoomsFromModules(boilerroomPos).find(b => b.id === boilerroomId);
  if (!boilerRoom) throw new Error("Boiler room not found");

  const plansWithId = useMemo(
    () => plans.filter((p): p is PlansMessageInner & { id: string } => p.id !== undefined),
    [plans],
  );
  const plansWithNameAndId = useMemo(() => plansWithId.map(p => ({ ...p, name: p.name ?? "-" })), [plansWithId]);
  const plan = plansWithId.find(p => p.id === boilerRoom.plan?.planId);

  const errors = getErrors(boilerRooms ?? []);

  const gatewayId = modules.find(m => m.id === boilerRoomModuleId)?.gatewayId;
  const gatewaySoftwareVersion = gateways.find(g => g.id === gatewayId)?.softwareVersion;
  const gatewayModuleIds = modules.filter(m => m.gatewayId === gatewayId).map(m => m.id!);
  const displayErrors = realTimeErrors?.some(e => e.moduleId && gatewayModuleIds.includes(e.moduleId)) ?? false;

  return (
    <>
      <Page.Header>
        <Breadcrumb.Root>
          <Breadcrumb.List>
            {plan && (
              <>
                <Breadcrumb.Item>
                  <Breadcrumb.Link asChild>
                    <Link to={routeToPlan(houseId, plan.id)}>{plan.name ?? "--"}</Link>
                  </Breadcrumb.Link>
                </Breadcrumb.Item>
                <Breadcrumb.Separator />
              </>
            )}
            <Breadcrumb.Item>
              <Breadcrumb.Page asChild>
                <Page.Title>{currentBoilerRoom?.name ?? boilerRoom.name ?? "--"}</Page.Title>
              </Breadcrumb.Page>
            </Breadcrumb.Item>
          </Breadcrumb.List>
        </Breadcrumb.Root>
        {!history && (
          <Parameters
            boilerRoomId={boilerroomId}
            {...currentBoilerRoom}
            boilerroomPos={boilerroomPos.flatMap(bp => bp.boilerRooms ?? [])}
            disabled={!currentBoilerRoom}
          />
        )}
        <Button asChild>
          <Link to={routeToFrostFreeSettings(houseId)} className={styles.header__link}>
            <div className={styles.header__frostFree} />
            {t("boilerRoom.frostFreePeriod")}
          </Link>
        </Button>
        <Button asChild>
          <Link to={routeToSeasonSettings(houseId)} className={styles.header__link}>
            <SunIcon />
            {t("boilerRoom.season")}
          </Link>
        </Button>

        <DisplayDropdownMenu
          houseId={houseId}
          boilerRoomId={boilerroomId}
          gatewaySoftwareVersion={gatewaySoftwareVersion}
          modules={modules}
          disabled={!currentBoilerRoom}
        />

        <ActionsDropdownMenu
          houseId={houseId}
          boilerroomPos={boilerroomPos}
          gateways={gateways}
          modules={modules}
          disabled={!currentBoilerRoom}
        />
      </Page.Header>
      <Page.Content className={styles.content}>
        <div className={styles.content__scheme}>
          {displayErrors ? (
            <Alert severity="error">
              <div className={styles.content__alert}>
                <Typography as="h3">{t("boilerRoom.popup.error.title")}</Typography>
                <ErrorDialog errors={errorMessages}>
                  <Button className={styles.content__button}>{t("boilerRoom.errors.display")}</Button>
                </ErrorDialog>
              </div>
            </Alert>
          ) : currentBoilerRoom ? (
            <>
              <Boilerroom layoutState={layout} />
              {history && <HistorySlider />}
            </>
          ) : (
            <Loader />
          )}
        </div>
        <ErrorCollapsible className={styles.content__error} houseId={houseId} errors={errors} />
        <RightColumn
          className={styles.content__column}
          houseId={houseId}
          boilerRoomId={boilerroomId}
          boilerRooms={boilerRooms ?? []}
          boilerRoomPos={boilerroomPos}
        />
        <LiveHistoryToggle
          className={styles.content__liveHistoryToggle}
          mode={mode}
          onModeChange={onModeChange}
          date={date}
        />
      </Page.Content>
      <PositioningDialog
        open={positioningDialogOpen}
        onOpenChange={setPositioningDialogOpen}
        houseId={houseId}
        boilerRoomsPos={boilerroomPos}
        plans={plansWithNameAndId}
      />
      {!history && heatingNetworksToConfigure.length > 0 && (
        <RoomsDialog defaultOpen heatingNetworkToConfigure={heatingNetworksToConfigure} />
      )}
    </>
  );
};

BoilerRoomPage.displayName = NAME;

export { BoilerRoomPage };
